import React from 'react';
import "./index.scss";
import { animateBox } from "../../modules/componentAnimation";
import * as backendModule from "../../modules/backendModule";
import axios from 'axios';
import moment from 'moment';

import Spinner from "../../components/Spinner";

export default function Report(props) {
    const [spinner, setSpinner] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [filter, setFilter] = React.useState({ name: 'Clicks', order: 'desc' });
    const [showLink, setShowLink] = React.useState({enabled: false, ID: null});
    const [data, setData] = React.useState([]);

    const productRef = React.useRef(null);

    const getData = () => {
        setSpinner(true);

        if (filter.name === 'CPC' || filter.name === 'CPL') {
            const tmpArray = [];

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                data: {
                    filters: [{ name: 'UserID', op: 'eq', value: props.userID }, productRef.current.value && { name: 'Product', op: 'eq', value: productRef.current.value }],
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    res.data.data?.map(item => {
                        if (filter.name === 'CPC') {
                            tmpArray.push({ ID: item.ID, Name: item.Name, Product: item.Product, Currency: item.Currency, LandingLink: item.LandingLink, CPC: (Number(item.Spent) / Number(item.Clicks)).toFixed(2), createdAt: item.createdAt });
                        }
                        else {
                            tmpArray.push({ ID: item.ID, Name: item.Name, Product: item.Product, Currency: item.Currency, LandingLink: item.LandingLink, CPL: (Number(item.Spent) / Number(item.Leads)).toFixed(2), createdAt: item.createdAt });
                        }
                    });
                    setData({ status: 'ok', data: filter.name === 'CPC' ? tmpArray.sort((a, b) => a.CPC - b.CPC) : tmpArray.sort((a, b) => a.CPL - b.CPL) });
                    setErrorMsg(null);
                } else {
                    return setErrorMsg('Dohvatanje podataka nije uspelo, pokušaj ponovo');
                };
            }).catch(() => {
                return setErrorMsg('Server ne reagira...');
            }).finally(() => {
                setSpinner(false);
            });
        }
        else {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                data: {
                    filters: [{ name: 'UserID', op: 'eq', value: props.userID }, productRef.current.value && { name: 'Product', op: 'eq', value: productRef.current.value }],
                    orders: [{ name: filter.name, order: filter.order }]
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setData(res.data);
                    setErrorMsg(null);
                } else {
                    return setErrorMsg('Dohvatanje podataka nije uspelo, pokušaj ponovo');
                };
            }).catch(() => {
                return setErrorMsg('Server ne reagira...');
            }).finally(() => {
                setSpinner(false);
            });
        }
    }

    React.useEffect(() => {
        getData();
    }, [filter]);

    return (
        <div className='route__report'>
            <div className='route__report__header'>
                <h1 className='route__report__header__title'>Izvještaj</h1>
            </div>
            <div className='route__report__wrapper'>
                <div className='route__report__wrapper__table'>
                    <div className='route__report__wrapper__table__head'>
                        <div onClick={() => setFilter({ name: 'Clicks', order: 'desc' })} style={{ borderBottom: filter.name == 'Clicks' && '3px solid #FF4C29' }} className='route__report__wrapper__table__head__element'>
                            <p>Klikovi</p>
                        </div>
                        <div onClick={() => setFilter({ name: 'Leads', order: 'desc' })} style={{ borderBottom: filter.name == 'Leads' && '3px solid #FF4C29' }} className='route__report__wrapper__table__head__element'>
                            <p>Leadovi</p>
                        </div>
                        <div onClick={() => setFilter({ name: 'CPL', order: 'asc' })} style={{ borderBottom: filter.name == 'CPL' && '3px solid #FF4C29' }} className='route__report__wrapper__table__head__element'>
                            <p>CPL</p>
                        </div>
                        <div onClick={() => setFilter({ name: 'CPC', order: 'asc' })} style={{ borderBottom: filter.name == 'CPC' && '3px solid #FF4C29' }} className='route__report__wrapper__table__head__element'>
                            <p>CPC</p>
                        </div>
                        <div className='route__report__wrapper__table__head__element'>
                            <input ref={productRef} type='text' placeholder='Proizvod' />
                        </div>
                        <div onClick={() => getData()} className='route__report__wrapper__table__head__button'>
                            <p>Primijeni</p>
                        </div>
                    </div>
                    {
                        !spinner && !errorMsg ? data?.data?.map(item => {
                            return <div className='route__report__wrapper__table__campaign'>
                                <div onClick={() => setShowLink({enabled: true, ID: item.ID})} className='route__report__wrapper__table__campaign__info'>
                                    <p>ID: {item.ID}</p>
                                    <p>Naziv: {item.Name}</p>
                                    <p>Proizvod: {item.Product}</p>
                                    <p>{filter.name === 'Clicks' ? 'Klikova' : filter.name === 'Leads' ? 'Leadova' : filter.name === 'CPC' ? 'CPC' : 'CPL'}: {filter.name === 'Clicks' ? item.Clicks : filter.name === 'Leads' ? item.Leads : filter.name === 'CPC' ? item.CPC + ' ' + item.Currency : item.CPL + ' ' + item.Currency}</p>
                                    <p>Kreirano: {moment(item.createdAt).format('DD.MM.YYYY.')}</p>
                                </div>
                                <div style={{display: showLink.enabled && showLink.ID === item.ID ? 'block' : 'none'}} className='route__report__wrapper__table__campaign__link'>
                                    <p style={{fontStyle:'italic', fontSize: '14px'}}>Landing ID-{item.ID}: {item.LandingLink}</p>
                                </div>
                            </div>
                        }) : null
                    }
                    {!spinner && data?.data?.length === 0 ? <p style={{ color: '#EEEEEE' }} className='route__report__wrapper__table__campaign__info'>Trenutno nemate niti jednu kampanju</p> : null}
                    {spinner && <Spinner align='center' color='#FF4C29' style={{ width: '46px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />}
                    {errorMsg && <p style={{ color: '#FF4C29' }}>{errorMsg}</p>}
                </div>
            </div>
        </div>
    )
}
