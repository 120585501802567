import React from 'react';
import "./index.scss";
import { animateBox } from "../../modules/componentAnimation";
import currencies from "../../modules/currenciesModule";
import * as backendModule from "../../modules/backendModule";
import axios from 'axios';
import moment from 'moment';

import Spinner from "../../components/Spinner";

export default function Campaigns(props) {
  const [spinner, setSpinner] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState(null);

  const getAllCampaigns = () => {
    setSpinner(true);

    if(!props.userID) return;

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
      data: {
        orders: [{ name: 'ID', order: 'desc' }],
        filters: [{name: 'UserID', op: 'eq', value: props.userID}]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
      } else {
        return setErrorMsg('Dohvatanje podataka nije uspelo, pokušaj ponovo');
      };
    }).catch(() => {
      return setErrorMsg('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  React.useEffect(() => {
    getAllCampaigns();
  }, [props.userID]);

  return (
    <div className='route__campaigns'>
      <div className='route__campaigns__header'>
        <h1 className='route__campaigns__header__title'>Kampanje</h1>
        <button onClick={(e) => animateBox(e, <CreateModal userID={props.userID} getAllCampaigns={getAllCampaigns} />)} className='route__campaigns__header__button'>Nova kampanja</button>
      </div>

      {!spinner && !errorMsg && <div className='route__campaigns__wrapper'>
        <div className='route__campaigns__wrapper__table'>
          {window.innerWidth > 1150 && <div className='route__campaigns__wrapper__table__header'>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>ID</p>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>Naziv</p>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>Proizvod</p>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>Država</p>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>Valuta</p>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>Klikova</p>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>Leadova</p>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>Odobreno</p>
            <p className='route__campaigns__wrapper__table__header__centerAlign'>App. rate</p>
            <p className='route__campaigns__wrapper__table__header__centerAlign'>Konverz.</p>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>Payout</p>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>Potrošeno</p>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>CPC</p>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>CPL</p>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>Profit</p>
            <p className='route__campaigns__wrapper__table__header__leftAlign'>Kreirano</p>
            <p className='route__campaigns__wrapper__table__header__rightAlign'>Akcije</p>
          </div>}

          {
            !spinner && data?.data?.map(campaign => {
              return <div className='route__campaigns__wrapper__table__data'>
                <p>{window.innerWidth < 1151 && <p>ID:</p>}{campaign.ID}</p>
                <p>{window.innerWidth < 1151 && <p>Naziv:</p>}{campaign.Name}</p>
                <p>{window.innerWidth < 1151 && <p>Proizvod:</p>}{campaign.Product}</p>
                <p>{window.innerWidth < 1151 && <p>Država:</p>}{campaign.Country}</p>
                <p>{window.innerWidth < 1151 && <p>Valuta:</p>}{campaign.Currency}</p>
                <p>{window.innerWidth < 1151 && <p>Klikova:</p>}{campaign.Clicks}</p>
                <p>{window.innerWidth < 1151 && <p>Leadova:</p>}{campaign.Leads}</p>
                <p>{window.innerWidth < 1151 && <p>Odobreno:</p>}{campaign.Confirmed}</p>
                <p className='route__campaigns__wrapper__table__data__centerAlign'>{window.innerWidth < 1151 && <p>Approve rate:</p>}{isNaN(Number(campaign.Confirmed) / Number(campaign.Leads) * 100) ? '0' : (Number(campaign.Confirmed) / Number(campaign.Leads) * 100).toFixed(0)}%</p>
                <p className='route__campaigns__wrapper__table__data__centerAlign'>{window.innerWidth < 1151 && <p>Konverzija:</p>}{isNaN(Number(campaign.Leads) / Number(campaign.Clicks) * 100) ? '0' : (Number(campaign.Leads) / Number(campaign.Clicks) * 100).toFixed(0)}%</p>
                <p>{window.innerWidth < 1151 && <p>Payout:</p>}{campaign.Payout}</p>
                <p>{window.innerWidth < 1151 && <p>Potrošeno:</p>}{campaign.Spent}</p>
                <p>{window.innerWidth < 1151 && <p>CPC:</p>}{isNaN(Number(campaign.Spent) / Number(campaign.Clicks)) ? '--' : (Number(campaign.Spent) / Number(campaign.Clicks)).toFixed(2)}</p>
                <p>{window.innerWidth < 1151 && <p>CPL:</p>}{isNaN(Number(campaign.Spent) / Number(campaign.Leads)) ? '--' :(Number(campaign.Spent) / Number(campaign.Leads)).toFixed(2)}</p>
                <p>{window.innerWidth < 1151 && <p>Profit:</p>}{(Number(campaign.Confirmed) * Number(campaign.Payout)) - Number(campaign.Spent)}</p>
                <p>{window.innerWidth < 1151 && <p>Kreirano:</p>}{moment(campaign.createdAt).format('DD.MM.YYYY.')}</p>
                <div onClick={(e) => animateBox(e, <DetailsModal getAll={getAllCampaigns} campaignID={campaign.ID} />)} className='route__campaigns__wrapper__table__data__button'>VIŠE</div>
              </div>
            })
          }
          {!spinner && data?.data?.length === 0 ? <p style={{padding: '5px'}}>Trenutno nemate niti jednu kampanju</p> : null}

        </div>
      </div>}
      {spinner && !errorMsg && <Spinner align='center' color='#FF4C29' style={{ marginTop: '20px' }} />}
      {!spinner && errorMsg && <p style={{ color: '#FF4C29', marginLeft: '20px', marginTop: '20px' }}>{errorMsg}</p>}
    </div>
  )
}


const CreateModal = (props) => {
  const [dropdown, setDropdown] = React.useState(false);
  const [dropdownSelected, setDropdownSelected] = React.useState(currencies[0]);
  const [domainDropdown, setDomainDropdown] = React.useState(false);
  const [domainDropdownSelected, setDomainDropdownSelected] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const [mainSpinner, setMainSpinner] = React.useState(true);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [domains, setDomains] = React.useState([]);

  const nameRef = React.useRef(null);
  const productRef = React.useRef(null);
  const countryRef = React.useRef(null);
  const payoutRef = React.useRef(null);
  const landingLinkRef = React.useRef(null);

  const createCampaign = () => {
    if (
      !nameRef.current.value ||
      !productRef.current.value ||
      !countryRef.current.value ||
      !payoutRef.current.value ||
      !landingLinkRef.current.value ||
      !domainDropdownSelected
    ) {
      return setErrorMsg('Sva polja moraju biti popunjena!');
    }
    else {
      if (!Number(payoutRef.current.value)) {
        return setErrorMsg('Payout smije sadržavati samo brojeve!');
      }
      else {
        if (String(nameRef.current.value).length > 16) {
          return setErrorMsg('Naziv smije sadržavati najviše 16 karaktera!');
        }
        else {
          setSpinner(true);
        }
      }
    }

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/campaigns/createCampaign`,
      data: {
        UserID: props.userID,
        Name: nameRef.current.value,
        Product: productRef.current.value,
        Country: countryRef.current.value,
        Payout: payoutRef.current.value,
        LandingLink: landingLinkRef.current.value,
        Currency: dropdownSelected,
        Domain: domainDropdownSelected
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.onClose();
        props.getAllCampaigns();
      } else {
        return setErrorMsg('Generisanje nije uspelo, pokušaj ponovo');
      };
    }).catch(() => {
      return setErrorMsg('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  const getAllDomains = () => {
    setMainSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/apikeys/getAllDomains`,
      data: {
        filters: [{name: 'UserID', op: 'eq', value: props.userID}]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setDomains(res.data);
        setDomainDropdownSelected(res.data.data[0]?.Domain)
      } else {
        return setErrorMsg('Dohvatanje podataka nije uspelo, pokušaj ponovo');
      };
    }).catch(() => {
      return setErrorMsg('Server ne reagira...');
    }).finally(() => {
      setMainSpinner(false);
    });
  }

  React.useEffect(() => {
    getAllDomains();
  }, []);

  return <div className='route__campaigns__createModal'>
    <div className='route__campaigns__createModal__wrapper'>
      <div className='route__campaigns__createModal__wrapper__header'>
        <h1>Kreiraj novu kampanju</h1>
        <img onClick={props.onClose} src='images/close.png' alt='' />
      </div>
      {!mainSpinner && <div className='route__campaigns__createModal__wrapper__content'>
        <div className='route__campaigns__createModal__wrapper__content__field'>
          <label>Naziv kampanje</label>
          <input ref={nameRef} type='text' />
        </div>
        <div className='route__campaigns__createModal__wrapper__content__field'>
          <label>Proizvod</label>
          <input ref={productRef} type='text' />
        </div>
        <div className='route__campaigns__createModal__wrapper__content__field'>
          <label>Država (ISO 3166)</label>
          <input ref={countryRef} type='text' />
        </div>
        <div className='route__campaigns__createModal__wrapper__content__field'>
          <label>Payout</label>
          <input ref={payoutRef} type='text' />
        </div>
        <div className='route__campaigns__createModal__wrapper__content__field'>
          <label>Landing link</label>
          <input ref={landingLinkRef} type='text' />
        </div>
        <div className='route__campaigns__createModal__wrapper__content__dropdown'>
          <label>Valuta</label>
          <div onClick={() => setDropdown(d => !d)} className={`${dropdown ? 'route__campaigns__createModal__wrapper__content__dropdown__head activeDropdownHead' : 'route__campaigns__createModal__wrapper__content__dropdown__head'}`}>
            <p>{dropdownSelected}</p>
            <img src='images/downArrow.png' alt='' />
          </div>
          <div className={`${dropdown ? 'route__campaigns__createModal__wrapper__content__dropdown__body activeDropdown' : 'route__campaigns__createModal__wrapper__content__dropdown__body'}`}>
            {
              currencies.map((cur, index) => {
                return <p onClick={() => { setDropdownSelected(cur); setDropdown(false) }}>{cur}</p>
              })
            }
          </div>
        </div>
      </div>}
      {!mainSpinner && <div className='route__campaigns__createModal__wrapper__domainDropdown'>
        <label>Domena za praćenje</label>
        <div onClick={() => setDomainDropdown(d => !d)} className={`${domainDropdown ? 'route__campaigns__createModal__wrapper__domainDropdown__head activeDomainDropdownHead' : 'route__campaigns__createModal__wrapper__domainDropdown__head'}`}>
          <p>{domainDropdownSelected}</p>
          <img src='images/downArrow.png' alt='' />
        </div>
        <div className={`${domainDropdown ? 'route__campaigns__createModal__wrapper__domainDropdown__body activeDomainDropdown' : 'route__campaigns__createModal__wrapper__domainDropdown__body'}`}>
          {
            domains?.data.map((dom) => {
              return <p onClick={() => { setDomainDropdownSelected(dom.Domain); setDomainDropdown(false) }}>{dom.Domain}</p>
            })
          }
        </div>
      </div>}
      {!mainSpinner && <div className='route__campaigns__createModal__wrapper__bottom'>
        {errorMsg && <p className='route__campaigns__createModal__wrapper__bottom__errorMsg'>{errorMsg}</p>}
        <div onClick={() => createCampaign()} className='route__campaigns__createModal__wrapper__bottom__btn'>
          {!spinner && <p>Generiši pixel</p>}
          {spinner && <Spinner color='white' style={{ width: '22px' }} />}
        </div>
      </div>}
      {mainSpinner && <Spinner color='#FF4C29' style={{ width: '42px' }} />}
    </div>
  </div>
}

const DetailsModal = (props) => {
  const [data, setData] = React.useState([]);
  const [spinner, setSpinner] = React.useState(true);
  const [saveSpinner, setSaveSpinner] = React.useState(false);
  const [deleteSpinner, setDeleteSpinner] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [saveError, setSaveError] = React.useState(null);

  const todaySpentRef = React.useRef(null);
  const totalSpentRef = React.useRef(null);
  const todayConfirmedRef = React.useRef(null);
  const totalConfirmedRef = React.useRef(null);

  const getData = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/campaigns/getCampaignByID`,
      data: { ID: props.campaignID },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
      } else {
        return setErrorMsg('Dohvatanje podataka nije uspelo, pokušaj ponovo');
      };
    }).catch(() => {
      return setErrorMsg('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  const saveChanges = () => {
    if (
      !todaySpentRef.current.value &&
      !totalSpentRef.current.value &&
      !todayConfirmedRef.current.value &&
      !totalConfirmedRef.current.value
    ) {
      return;
    }
    else {
      if (
        todaySpentRef.current.value && !Number(todaySpentRef.current.value) ||
        totalSpentRef.current.value && !Number(totalSpentRef.current.value) ||
        todayConfirmedRef.current.value && !Number(todayConfirmedRef.current.value) ||
        totalConfirmedRef.current.value && !Number(totalConfirmedRef.current.value)
      ) {
        return setSaveError('Dozvoljen je samo unos brojeva!');
      }
      else {
        setSaveSpinner(true);
      }
    }

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/campaigns/editCampaign`,
      data: {
        ID: props.campaignID,
        Spent: totalSpentRef.current.value ? totalSpentRef.current.value : null,
        Confirmed: totalConfirmedRef.current.value ? totalConfirmedRef.current.value : null,
        TodayStats: {
          Spent: Number(todaySpentRef.current.value) > 0 ? todaySpentRef.current.value : 0,
          Confirmed: Number(todayConfirmedRef.current.value) > 0 ? todayConfirmedRef.current.value : 0
        }
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.getAll();
        props.onClose();
      } else {
        return setSaveError('Dohvatanje podataka nije uspelo, pokušaj ponovo');
      };
    }).catch(() => {
      return setSaveError('Server ne reagira...');
    }).finally(() => {
      setSaveSpinner(false);
    });
  }

  const deleteCampaign = (ID) => {
    setDeleteSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/campaigns/deleteCampaign`,
      data: {
        ID: ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.getAll();
        props.onClose();
      } else {
        return setSaveError('Brisanje nije uspelo, pokušaj ponovo');
      };
    }).catch(() => {
      return setSaveError('Server ne reagira...');
    }).finally(() => {
      setDeleteSpinner(false);
    });
  }

  React.useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  return <div className='route__campaigns__detailsModal'>
    <div className='route__campaigns__detailsModal__wrapper'>
      <div className='route__campaigns__detailsModal__wrapper__header'>
        <h1>Detalji za "{data?.data?.data?.Name}"</h1>
        <img onClick={props.onClose} src='images/close.png' alt='' />
      </div>
      <div className='route__campaigns__detailsModal__wrapper__content'>
        <div className='route__campaigns__detailsModal__wrapper__content__today'>
          <div className='route__campaigns__detailsModal__wrapper__content__today__header'>
            <h1>Statistika {moment().format('DD.MM.YYYY.')}</h1>
          </div>
          <div className='route__campaigns__detailsModal__wrapper__content__today__body'>
            <div className='route__campaigns__detailsModal__wrapper__content__today__body__data'>
              <div className='route__campaigns__detailsModal__wrapper__content__today__body__data__head'>
                <p>Klikova</p>
                <img src='images/click.png' alt='' />
              </div>
              {data?.data?.data?.TodayStats && <p>{JSON.parse(data?.data?.data?.TodayStats).Clicks}</p>}
            </div>
            <div className='route__campaigns__detailsModal__wrapper__content__today__body__data'>
              <div className='route__campaigns__detailsModal__wrapper__content__today__body__data__head'>
                <p>Leadova</p>
                <img src='images/leads.png' alt='' />
              </div>
              {data?.data?.data?.TodayStats && <p>{JSON.parse(data?.data?.data?.TodayStats).Leads}</p>}
            </div>
            <div className='route__campaigns__detailsModal__wrapper__content__today__body__data'>
              <div className='route__campaigns__detailsModal__wrapper__content__today__body__data__head'>
                <p>Odobreno</p>
                <img src='images/confirmed.png' alt='' />
              </div>
              {data?.data?.data?.TodayStats && <p>{JSON.parse(data?.data?.data?.TodayStats).Confirmed}</p>}
            </div>
            <div className='route__campaigns__detailsModal__wrapper__content__today__body__data'>
              <div className='route__campaigns__detailsModal__wrapper__content__today__body__data__head'>
                <p>Approve rate</p>
                <img src='images/approverate.png' alt='' />
              </div>
              {data?.data?.data?.TodayStats && <p>{isNaN(Number(JSON.parse(data?.data?.data?.TodayStats).Confirmed) / Number(JSON.parse(data?.data?.data?.TodayStats).Leads) * 100) ? '0' : (Number(JSON.parse(data?.data?.data?.TodayStats).Confirmed) / Number(JSON.parse(data?.data?.data?.TodayStats).Leads) * 100).toFixed(2)}%</p>}
            </div>
            <div className='route__campaigns__detailsModal__wrapper__content__today__body__data'>
              <div className='route__campaigns__detailsModal__wrapper__content__today__body__data__head'>
                <p>Konverzija</p>
                <img src='images/conversion.png' alt='' />
              </div>
              {data?.data?.data?.TodayStats && <p>{isNaN(Number(JSON.parse(data?.data?.data?.TodayStats).Leads) / Number(JSON.parse(data?.data?.data?.TodayStats).Clicks) * 100) ? '0' : (Number(JSON.parse(data?.data?.data?.TodayStats).Leads) / Number(JSON.parse(data?.data?.data?.TodayStats).Clicks) * 100).toFixed(2)}%</p>}
            </div>
            <div className='route__campaigns__detailsModal__wrapper__content__today__body__data'>
              <div className='route__campaigns__detailsModal__wrapper__content__today__body__data__head'>
                <p>Potrošeno</p>
                <img src='images/spent.png' alt='' />
              </div>
              {data?.data?.data?.TodayStats && <p>{JSON.parse(data?.data?.data?.TodayStats).Spent} {data?.data?.data?.Currency}</p>}
            </div>
            <div className='route__campaigns__detailsModal__wrapper__content__today__body__data'>
              <div className='route__campaigns__detailsModal__wrapper__content__today__body__data__head'>
                <p>CPC</p>
                <img src='images/cpc.png' alt='' />
              </div>
              {data?.data?.data?.TodayStats && <p>{isNaN(Number(JSON.parse(data?.data?.data?.TodayStats).Spent) / Number(JSON.parse(data?.data?.data?.TodayStats).Clicks)) ? '--' :(Number(JSON.parse(data?.data?.data?.TodayStats).Spent) / Number(JSON.parse(data?.data?.data?.TodayStats).Clicks)).toFixed(2)} {data?.data?.data?.Currency}</p>}
            </div>
            <div className='route__campaigns__detailsModal__wrapper__content__today__body__data'>
              <div className='route__campaigns__detailsModal__wrapper__content__today__body__data__head'>
                <p>CPL</p>
                <img src='images/cpl.png' alt='' />
              </div>
              {data?.data?.data?.TodayStats && <p>{isNaN(Number(JSON.parse(data?.data?.data?.TodayStats).Spent) / Number(JSON.parse(data?.data?.data?.TodayStats).Leads)) ? '--' : (Number(JSON.parse(data?.data?.data?.TodayStats).Spent) / Number(JSON.parse(data?.data?.data?.TodayStats).Leads)).toFixed(2)} {data?.data?.data?.Currency}</p>}
            </div>
            <div className='route__campaigns__detailsModal__wrapper__content__today__body__data'>
              <div className='route__campaigns__detailsModal__wrapper__content__today__body__data__head'>
                <p>Profit</p>
                <img src='images/profit.png' alt='' />
              </div>
              {data?.data?.data?.TodayStats && <p>{(Number(JSON.parse(data?.data?.data?.TodayStats).Confirmed) * Number(data?.data?.data?.Payout)) - Number(JSON.parse(data?.data?.data?.TodayStats).Spent)} {data?.data?.data?.Currency}</p>}
            </div>
          </div>
        </div>
        <div className='route__campaigns__detailsModal__wrapper__content__link'>
          <strong>Landing link:</strong>
          <p>{data?.data?.data?.LandingLink}</p>
        </div>
        <div className='route__campaigns__detailsModal__wrapper__content__link'>
          <strong>Domena za praćenje:</strong>
          <p>{data?.data?.data?.Domain}</p>
        </div>
        <div className='route__campaigns__detailsModal__wrapper__content__spending'>
          <div className='route__campaigns__detailsModal__wrapper__content__spending__field'>
            <p>Unesi današnju potrošnju</p>
            <input ref={todaySpentRef} type='text' />
          </div>
          <div className='route__campaigns__detailsModal__wrapper__content__spending__field'>
            <p>Unesi ukupnu potrošnju</p>
            <input ref={totalSpentRef} type='text' />
          </div>
        </div>
        <div className='route__campaigns__detailsModal__wrapper__content__confirmed'>
          <div className='route__campaigns__detailsModal__wrapper__content__confirmed__field'>
            <p>Unesi odobreno danas</p>
            <input ref={todayConfirmedRef} type='text' />
          </div>
          <div className='route__campaigns__detailsModal__wrapper__content__confirmed__field'>
            <p>Unesi odobreno ukupno</p>
            <input ref={totalConfirmedRef} type='text' />
          </div>
        </div>
        <div className='route__campaigns__detailsModal__wrapper__content__buttons'>
          <div onClick={() => saveChanges()} className='route__campaigns__detailsModal__wrapper__content__buttons__btn'>
            {!saveSpinner && <p>Sačuvaj promjene</p>}
            {saveSpinner && <Spinner color='white' style={{ width: '22px' }} />}
          </div>
          <div onClick={(e) => animateBox(e, <PixelModal data={data?.data?.data} />)} className='route__campaigns__detailsModal__wrapper__content__buttons__btn'>
            <p>Uzmi pixel</p>
          </div>
          <div onClick={() => deleteCampaign(data?.data?.data?.ID)} className='route__campaigns__detailsModal__wrapper__content__buttons__btn'>
            {!deleteSpinner && <p>Obriši kampanju</p>}
            {deleteSpinner && <Spinner color='white' style={{ width: '22px' }} />}
          </div>
        </div>
        {saveError && <p>{saveError}</p>}
      </div>
      {spinner && !errorMsg && <Spinner color='#FF4C29' align='center' />}
      {!spinner && errorMsg && <p className='route__campaigns__detailsModal__wrapper__errorMsg'>{errorMsg}</p>}
    </div>
  </div>
}

const PixelModal = (props) => {
  const [data, setData] = React.useState([]);
  const [spinner, setSpinner] = React.useState(true);
  const [errorMsg, setErrorMsg] = React.useState(null);

  const getData = () => {
    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/apikeys/getKeyByDomain`,
      data: {
        Domain: props.data?.Domain
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
      } else {
        return setErrorMsg('Dohvatanje podataka nije uspelo, pokušaj ponovo');
      };
    }).catch(() => {
      return setErrorMsg('Server ne reagira...');
    }).finally(() => {
      setSpinner(false);
    });
  }

  React.useEffect(() => {
    getData();
  }, []);

  const clicksPixel = `    <script defer>
  document.addEventListener("DOMContentLoaded", function () {
      var xhr = new XMLHttpRequest();
      var currentURL = window.location.href; 
      var url = "${backendModule.backendURL}/campaigns/tracking";
      var params = JSON.stringify({
          ID: ${props.data?.ID},
          Tag: 'clicks',
          Clicks: 1,
          ApiKey: '${data?.data?.data?.Key}',
          Domain: currentURL
      });

      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-type", "application/json");

      xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
              if (xhr.status == 200) {
                  var res = JSON.parse(xhr.responseText);
              }
          }
      };

      xhr.send(params);
  });
</script>`;

  const leadsPixel = `    <script>
document.addEventListener("DOMContentLoaded", function () {
    var xhr = new XMLHttpRequest();
    var currentURL = window.location.href; 
    var url = "${backendModule.backendURL}/campaigns/tracking";
    var params = JSON.stringify({
        ID: ${props.data?.ID},
        Tag: 'leads',
        Leads: 1,
        ApiKey: '${data?.data?.data?.Key}',
        Domain: currentURL
    });

    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-type", "application/json");

    xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
            if (xhr.status == 200) {
                var res = JSON.parse(xhr.responseText);
            }
        }
    };

    xhr.send(params);
});
</script>`;

  return <div className='route__campaigns__pixelModal'>
    <div className='route__campaigns__pixelModal__wrapper'>
      <div className='route__campaigns__pixelModal__wrapper__header'>
        <h1>Pixel za "{props.data?.Name}"</h1>
        <img onClick={props.onClose} src='images/close.png' alt='' />
      </div>
      {!spinner && !errorMsg && <div className='route__campaigns__pixelModal__wrapper__content'>
        <div className='route__campaigns__pixelModal__wrapper__content__box'>
          <label>Praćenje klikova</label>
          <code>
            {clicksPixel}
          </code>
        </div>
        <div className='route__campaigns__pixelModal__wrapper__content__box'>
          <label>Praćenje leadova</label>
          <code>{leadsPixel}</code>
        </div>
      </div>}
      {spinner && <Spinner color='#FF4C29' style={{ width: '42px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} align='center' />}
      {errorMsg && <p style={{ color: '#FF4C29' }}>{errorMsg}</p>}
    </div>
  </div>
}