import React from 'react';
import "./index.scss";
import { animateBox } from "../../modules/componentAnimation";
import * as backendModule from "../../modules/backendModule";
import axios from 'axios';
import moment from 'moment';

import Spinner from "../../components/Spinner";

export default function ApiKeys(props) {
    const [spinner, setSpinner] = React.useState(true);
    const [deleteSpinner, setDeleteSpinner] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [errorMsg, setErrorMsg] = React.useState(null);

    const getAllKeys = () => {
        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/apikeys/getAllKeys`,
            data: {
                filters: [{name: 'UserID', op: 'eq', value: props.userID}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                return setErrorMsg('Dohvatanje podataka nije uspelo, pokušaj ponovo');
            };
        }).catch(() => {
            return setErrorMsg('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const deleteKey = (ID) => {
        setDeleteSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/apikeys/deleteKey`,
            data: {
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getAllKeys();
            } else {
                return setErrorMsg('Brisanje nije uspelo, pokušaj ponovo');
            };
        }).catch(() => {
            return setErrorMsg('Server ne reagira...');
        }).finally(() => {
            setDeleteSpinner(false);
        });
    }

    React.useEffect(() => {
        getAllKeys();
    }, []);

    return (
        <div className='route__apikeys'>
            <div className='route__apikeys__header'>
                <h1 className='route__apikeys__header__title'>API tokeni</h1>
                <button onClick={(e) => animateBox(e, <CreateModal userID={props.userID} getAllKeys={getAllKeys} />)} className='route__apikeys__header__button'>Novi token</button>
            </div>

            {!spinner && !errorMsg && <div className='route__apikeys__wrapper'>
                <div className='route__apikeys__wrapper__table'>
                    <div className='route__apikeys__wrapper__table__header'>
                        <p className='route__apikeys__wrapper__table__header__leftAlign'>ID</p>
                        <p className='route__apikeys__wrapper__table__header__leftAlign'>Domena</p>
                        <p className='route__apikeys__wrapper__table__header__leftAlign'>Token</p>
                        <p className='route__apikeys__wrapper__table__header__leftAlign'>Kreirano</p>
                        <p className='route__apikeys__wrapper__table__header__rightAlign'>Akcije</p>
                    </div>

                    {
                        !spinner && data?.data?.map(apikey => {
                            return <div className='route__apikeys__wrapper__table__data'>
                                <p>{window.innerWidth < 1046 && <p style={{fontWeight: '600'}}>ID:</p>}{apikey.ID}</p>
                                <p>{window.innerWidth < 1046 && <p style={{fontWeight: '600'}}>Domena:</p>}{apikey.Domain}</p>
                                <p>{window.innerWidth < 1046 && <p style={{fontWeight: '600'}}>Token:</p>}<span>{apikey.Key}</span></p>
                                <p>{window.innerWidth < 1046 && <p style={{fontWeight: '600'}}>Kreirano:</p>}{moment(apikey.createdAt).format('DD.MM.YYYY.')}</p>
                                <div onClick={() => deleteKey(apikey.ID)} className='route__apikeys__wrapper__table__data__button'>
                                    {!deleteSpinner && <p>OBRIŠI</p>}
                                    {deleteSpinner && <Spinner color='white' style={{ width: '22px' }} />}
                                </div>
                            </div>
                        })
                    }

                    {!spinner && data?.data?.length === 0 ? <p style={{ padding: '5px' }}>Trenutno nemate niti jedan API token</p> : null}

                </div>
            </div>}
            {spinner && !errorMsg && <Spinner align='center' color='#FF4C29' style={{ marginTop: '20px' }} />}
            {!spinner && errorMsg && <p style={{ color: '#FF4C29', marginLeft: '20px', marginTop: '20px' }}>{errorMsg}</p>}
        </div>
    )
}


const CreateModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(null);

    const domainRef = React.useRef(null);

    const createKey = () => {
        if (
            !domainRef.current.value
        ) {
            return setErrorMsg('Unesite domenu!');
        }
        else {
            if (!String(domainRef.current.value).includes('http')) {
                return setErrorMsg('URL mora počinjati sa http ili https!');
            }
            setSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/apikeys/createKey`,
            data: {
                Domain: String(domainRef.current.value).endsWith("/") ? domainRef.current.value : (domainRef.current.value + "/"),
                UserID: props.userID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
                props.getAllKeys();
            } else {
                return setErrorMsg('Generisanje nije uspelo, pokušaj ponovo');
            };
        }).catch(() => {
            return setErrorMsg('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className='route__apikeys__createModal'>
        <div className='route__apikeys__createModal__wrapper'>
            <div className='route__apikeys__createModal__wrapper__header'>
                <h1>Novi API token</h1>
                <img onClick={props.onClose} src='images/close.png' alt='' />
            </div>
            <div className='route__apikeys__createModal__wrapper__content'>
                <div className='route__apikeys__createModal__wrapper__content__field'>
                    <label>URL domene</label>
                    <input ref={domainRef} type='text' />
                </div>
                <div onClick={() => createKey()} className='route__apikeys__createModal__wrapper__content__btn'>
                    {!spinner && <p>Generiši token</p>}
                    {!errorMsg && spinner && <Spinner color='white' style={{ width: '22px' }} />}
                </div>
                {!spinner && errorMsg && <p style={{ color: '#FF4C29' }}>{errorMsg}</p>}
            </div>
        </div>
    </div>
}