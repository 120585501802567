import React from 'react';
import "./index.scss";
import axios, { all } from "axios";
import * as backendModule from "../../modules/backendModule";
import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampActions";
import animateModule from "../../modules/animateModule";
import { Routes, Route, useNavigate } from "react-router-dom";

export default function Sidebar(props) {
    const [activeTab, setActiveTab] = React.useState(window.location.hash === '#/' || !window.location.hash ? 'campaigns' : String(window.location.hash).replace('/', '').replace('#', ''));
    const [logoutModal, setLogoutModal] = React.useState(false);
    const [mobileMenu, setMobileMenu] = React.useState(false);
    const [logoutMobileModal, setLogoutMobileModal] = React.useState(false);

    const mainNavigate = useNavigate();
    const curDispatch = useDispatch();

    const logout = () => {
        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            data: {},
            ...backendModule.axiosConfig
        }).then(res => {
            curDispatch(timestampActions.updateTimestamp());
            window.location.reload();
        }).catch(() => {
        }).finally(() => {
        });
    }

    const animateNavigate = to => {
        animateModule(mainNavigate, to, document.querySelector(".route__contentWrapper__body__routes"));
    };

    return (
        <div className='component__sidebar'>
            <div className='component__sidebar__logo'>
                <h1>diTracking</h1>
            </div>
            <div onClick={() => setMobileMenu(m => !m)} className='component__sidebar__hamburger'>
                <img src='images/hamburger.png' alt='' />
            </div>
            <div className='component__sidebar__nav'>
                <div onClick={(e) => { setActiveTab('campaigns'); animateNavigate('/') }} className={`${activeTab === 'campaigns' ? 'component__sidebar__nav__element active' : 'component__sidebar__nav__element'}`}>
                    <img src='images/kampanje.png' alt='' />
                    <p>Kampanje</p>
                </div>
                <div onClick={(e) => { setActiveTab('report'); animateNavigate('/report') }} className={`${activeTab === 'report' ? 'component__sidebar__nav__element active' : 'component__sidebar__nav__element'}`}>
                    <img src='images/report.png' alt='' />
                    <p>Izvještaj</p>
                </div>
                {props.userInfo?.Flags?.isAdmin && <div onClick={(e) => { setActiveTab('users'); animateNavigate('/users') }} className={`${activeTab === 'users' ? 'component__sidebar__nav__element active' : 'component__sidebar__nav__element'}`}>
                    <img src='images/users.png' alt='' />
                    <p>Korisnici</p>
                </div>}
                <div onClick={(e) => { setActiveTab('apikeys'); animateNavigate('/apikeys') }} className={`${activeTab === 'apikeys' ? 'component__sidebar__nav__element active' : 'component__sidebar__nav__element'}`}>
                    <img src='images/api.png' alt='' />
                    <p>API tokeni</p>
                </div>
            </div>

            <div className='component__sidebar__userInfo'>
                <img className='component__sidebar__userInfo__profileImg' src='images/defaultUser.jpeg' alt='' />
                <p>{props.userInfo?.FirstName} {props.userInfo?.LastName}</p>
                <img onClick={() => setLogoutModal(m => !m)} className='component__sidebar__userInfo__seemore' src='images/3dots.png' alt='' />

                <div onClick={() => logout()} className='component__sidebar__userInfo__logout' style={{ display: logoutModal ? 'flex' : 'none' }}>
                    <img src='images/logout.png' alt='' />
                    <p>Odjavi se</p>
                </div>
            </div>

            <div className={`${mobileMenu ? 'component__sidebar__mobileMenu activeMenu' : 'component__sidebar__mobileMenu'}`}>
                <div className='component__sidebar__mobileMenu__logo'>
                    <h1>diTracking</h1>
                    <img onClick={() => setMobileMenu(false)} src='images/close.png' alt='' />
                </div>

                <div className='component__sidebar__mobileMenu__nav'>
                    <div onClick={(e) => { setActiveTab('campaigns'); animateNavigate('/'); setMobileMenu(false) }} className={`${activeTab === 'campaigns' ? 'component__sidebar__mobileMenu__nav__element active' : 'component__sidebar__mobileMenu__nav__element'}`}>
                        <img src='images/kampanje.png' alt='' />
                        <p>Kampanje</p>
                    </div>
                    <div onClick={(e) => { setActiveTab('report'); animateNavigate('/report'); setMobileMenu(false) }} className={`${activeTab === 'report' ? 'component__sidebar__mobileMenu__nav__element active' : 'component__sidebar__mobileMenu__nav__element'}`}>
                        <img src='images/report.png' alt='' />
                        <p>Izvještaj</p>
                    </div>
                    {props.userInfo?.Flags?.isAdmin && <div onClick={(e) => { setActiveTab('users'); animateNavigate('/users'); setMobileMenu(false) }} className={`${activeTab === 'users' ? 'component__sidebar__mobileMenu__nav__element active' : 'component__sidebar__mobileMenu__nav__element'}`}>
                        <img src='images/users.png' alt='' />
                        <p>Korisnici</p>
                    </div>}
                    <div onClick={(e) => { setActiveTab('apikeys'); animateNavigate('/apikeys'); setMobileMenu(false) }} className={`${activeTab === 'apikeys' ? 'component__sidebar__mobileMenu__nav__element active' : 'component__sidebar__mobileMenu__nav__element'}`}>
                        <img src='images/api.png' alt='' />
                        <p>API tokeni</p>
                    </div>
                </div>

                <div className='component__sidebar__mobileMenu__userInfo'>
                    <img className='component__sidebar__mobileMenu__userInfo__profileImg' src='images/defaultUser.jpeg' alt='' />
                    <p>{props.userInfo?.FirstName} {props.userInfo?.LastName}</p>
                    <img onClick={() => setLogoutMobileModal(m => !m)} className='component__sidebar__mobileMenu__userInfo__seemore' src='images/3dots.png' alt='' />

                    <div onClick={() => logout()} className='component__sidebar__mobileMenu__userInfo__logout' style={{ display: logoutMobileModal ? 'flex' : 'none' }}>
                        <img src='images/logout.png' alt='' />
                        <p>Odjavi se</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
