import Login from "./routes/Login";
import React from 'react';
import axios from "axios";
import * as backendModule from "./modules/backendModule";
import { Routes, Route, useNavigate } from "react-router-dom";

import Spinner from "./components/Spinner";
import Sidebar from "./components/Sidebar";

import Campaigns from "./routes/Campaigns";
import ApiKeys from "./routes/ApiKeys";
import Users from "./routes/Users";
import Report from "./routes/Report";

function App() {
  const [logged, setLogged] = React.useState(false);
  const [userData, setUserData] = React.useState([]);
  const [spinner, setSpinner] = React.useState(true);

  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setUserData(res.data);
        setLogged(true);
      }
      else {
        setLogged(false);
      }
    }).catch(() => {
      setLogged(false);
    }).finally(() => {
      setSpinner(false);
    });
  };

  React.useEffect(() => {
    checkLogin();
  }, []);

  return (
    <div className="route__contentWrapper">
      {spinner && <Spinner color='gray' align='center' />}
      {!spinner && <div className="route__contentWrapper__body">
        <Sidebar userInfo={userData?.data?.UserInfo} />
        <div className="route__contentWrapper__body__routes">
          <Routes>
            {
              logged ?
                <Route path="/" element={<Campaigns userID={userData?.data?.UserInfo?.ID} />} />
                : <Route path="/" element={<Login checkLogin={checkLogin} setLogged={setLogged} />} />
            }
            <Route path="/report" element={logged ? <Report userID={userData?.data?.UserInfo?.ID} /> : <Login checkLogin={checkLogin} setLogged={setLogged} />} /> : ''
            <Route path="/apikeys" element={logged ? <ApiKeys userID={userData?.data?.UserInfo?.ID} /> : <Login checkLogin={checkLogin} setLogged={setLogged} />} /> : ''
            <Route path="/users" element={logged && userData?.data?.UserInfo?.Flags?.isAdmin ? <Users /> : <Login checkLogin={checkLogin} setLogged={setLogged} />} /> : ''
          </Routes>
        </div>
      </div>}
    </div>
  );
}

export default App;