import React from 'react';
import "./index.scss";
import { animateBox } from "../../modules/componentAnimation";
import * as backendModule from "../../modules/backendModule";
import axios from 'axios';
import moment from 'moment';

import Spinner from "../../components/Spinner";

export default function Users() {
    const [spinner, setSpinner] = React.useState(true);
    const [deleteSpinner, setDeleteSpinner] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [errorMsg, setErrorMsg] = React.useState(null);

    const getAllUsers = () => {
        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {

            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                return setErrorMsg('Dohvatanje podataka nije uspelo, pokušaj ponovo');
            };
        }).catch(() => {
            return setErrorMsg('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const deleteUser = (ID) => {
        setDeleteSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/deleteUser`,
            data: {
                ID: ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                getAllUsers();
            } else {
                return setErrorMsg('Brisanje nije uspelo, pokušaj ponovo');
            };
        }).catch(() => {
            return setErrorMsg('Server ne reagira...');
        }).finally(() => {
            setDeleteSpinner(false);
        });
    }

    React.useEffect(() => {
        getAllUsers();
    }, []);

    return (
        <div className='route__users'>
            <div className='route__users__header'>
                <h1 className='route__users__header__title'>Korisnici</h1>
                <button onClick={(e) => animateBox(e, <CreateModal getAllUsers={getAllUsers} />)} className='route__users__header__button'>Novi korisnik</button>
            </div>

            {!spinner && !errorMsg && <div className='route__users__wrapper'>
                <div className='route__users__wrapper__table'>
                    <div className='route__users__wrapper__table__header'>
                        <p className='route__users__wrapper__table__header__leftAlign'>ID</p>
                        <p className='route__users__wrapper__table__header__leftAlign'>Ime</p>
                        <p className='route__users__wrapper__table__header__leftAlign'>Prezime</p>
                        <p className='route__users__wrapper__table__header__leftAlign'>Korisničko ime</p>
                        <p className='route__users__wrapper__table__header__leftAlign'>Broj telefona</p>
                        <p className='route__users__wrapper__table__header__leftAlign'>Država</p>
                        <p className='route__users__wrapper__table__header__leftAlign'>Admin</p>
                        <p className='route__users__wrapper__table__header__leftAlign'>Email</p>
                        <p className='route__users__wrapper__table__header__leftAlign'>Kreirano</p>
                        <p className='route__users__wrapper__table__header__rightAlign'>Akcije</p>
                    </div>

                    {
                        !spinner && data?.data?.map(user => {
                            return <div className='route__users__wrapper__table__data'>
                                <p>{window.innerWidth < 725 && <p style={{fontWeight: '600'}}>ID:</p>}{user.ID}</p>
                                <p>{window.innerWidth < 725 && <p style={{fontWeight: '600'}}>Ime:</p>}{user.FirstName}</p>
                                <p>{window.innerWidth < 725 && <p style={{fontWeight: '600'}}>Prezime:</p>}{user.LastName}</p>
                                <p>{window.innerWidth < 725 && <p style={{fontWeight: '600'}}>Korisničko ime:</p>}{user.Username}</p>
                                <p>{window.innerWidth < 725 && <p style={{fontWeight: '600'}}>Broj telefona:</p>}{user.PhoneNumber}</p>
                                <p>{window.innerWidth < 725 && <p style={{fontWeight: '600'}}>Država:</p>}{user.Country}</p>
                                <p style={{ background: window.innerWidth > 725 && user.Flags.isAdmin ? 'green' : window.innerWidth > 725 && 'red', width: window.innerWidth > 725 && 'fit-content', padding: window.innerWidth > 725 && '5px', borderRadius: window.innerWidth > 725 && '50%'}}>{window.innerWidth < 725 && <p style={{fontWeight: '600'}}>Admin:</p>}{user.Flags.isAdmin ? 'Da' : 'Ne'}</p>
                                <p>{window.innerWidth < 725 && <p style={{fontWeight: '600'}}>Email:</p>}{user.Email}</p>
                                <p>{window.innerWidth < 725 && <p style={{fontWeight: '600'}}>Kreirano:</p>}{moment(user.createdAt).format('DD.MM.YYYY.')}</p>
                                <div onClick={() => deleteUser(user.ID)} className='route__users__wrapper__table__data__button'>
                                    {!deleteSpinner && <p>OBRIŠI</p>}
                                    {deleteSpinner && <Spinner color='white' style={{ width: '22px' }} />}
                                </div>
                            </div>
                        })
                    }

                </div>
            </div>}
            {spinner && !errorMsg && <Spinner align='center' color='#FF4C29' style={{ marginTop: '20px' }} />}
            {!spinner && errorMsg && <p style={{ color: '#FF4C29', marginLeft: '20px', marginTop: '20px' }}>{errorMsg}</p>}
        </div>
    )
}

const CreateModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [isAdmin, setIsAdmin] = React.useState(false);

    const firstNameRef = React.useRef(null);
    const lastNameRef = React.useRef(null);
    const phoneNumberRef = React.useRef(null);
    const countryRef = React.useRef(null);
    const usernameRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const passwordRef = React.useRef(null);

    const createUser = () => {
        if (
            !firstNameRef.current.value ||
            !lastNameRef.current.value ||
            !phoneNumberRef.current.value ||
            !countryRef.current.value ||
            !usernameRef.current.value ||
            !emailRef.current.value
        ) {
            return setErrorMsg('Popunite sva polja!');
        }
        else {
            setSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/createNewUser`,
            data: {
                FirstName: firstNameRef.current.value,
                LastName: lastNameRef.current.value,
                PhoneNumber: phoneNumberRef.current.value,
                Country: countryRef.current.value,
                username: usernameRef.current.value,
                email: emailRef.current.value,
                password: passwordRef.current.value,
                flags: {isAdmin: isAdmin}
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.getAllUsers();
                props.onClose();
            } else {
                return setErrorMsg('Kreiranje nije uspelo, pokušaj ponovo');
            };
        }).catch(() => {
            return setErrorMsg('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className='route__users__createModal'>
        <div className='route__users__createModal__wrapper'>
            <div className='route__users__createModal__wrapper__header'>
                <h1>Novi korisnik</h1>
                <img onClick={props.onClose} src='images/close.png' alt='' />
            </div>
            <div className='route__users__createModal__wrapper__content'>
                <div className='route__users__createModal__wrapper__content__field'>
                    <label>Ime</label>
                    <input ref={firstNameRef} type='text' />
                </div>
                <div className='route__users__createModal__wrapper__content__field'>
                    <label>Prezime</label>
                    <input ref={lastNameRef} type='text' />
                </div>
                <div className='route__users__createModal__wrapper__content__field'>
                    <label>Broj telefona</label>
                    <input ref={phoneNumberRef} type='text' />
                </div>
                <div className='route__users__createModal__wrapper__content__field'>
                    <label>Država</label>
                    <input ref={countryRef} type='text' />
                </div>
                <div className='route__users__createModal__wrapper__content__field'>
                    <label>Korisničko ime</label>
                    <input ref={usernameRef} type='text' />
                </div>
                <div className='route__users__createModal__wrapper__content__field'>
                    <label>Email</label>
                    <input ref={emailRef} type='text' />
                </div>
                <div className='route__users__createModal__wrapper__content__field'>
                    <label>Šifra</label>
                    <input ref={passwordRef} type='password' />
                </div>
                <div className='route__users__createModal__wrapper__content__field'>
                    <label>Admin</label>
                    <div className='route__users__createModal__wrapper__content__field__options'>
                        <p 
                        onClick={() => setIsAdmin(true)}
                        style={{border: isAdmin ? '2px solid #082032' : '2px solid #EEEEEE'}}
                        >Da</p>
                        <p 
                        onClick={() => setIsAdmin(false)}
                        style={{border: !isAdmin ? '2px solid #082032' : '2px solid #EEEEEE'}}
                        >Ne</p>
                    </div>
                </div>
            </div>
            <div className='route__users__createModal__wrapper__bottom'>
                <div onClick={() => createUser()} className='route__users__createModal__wrapper__bottom__btn'>
                    {!spinner && <p>Kreiraj korisnika</p>}
                    {!errorMsg && spinner && <Spinner color='white' style={{ width: '22px' }} />}
                </div>
                {!spinner && errorMsg && <p style={{ color: '#FF4C29' }}>{errorMsg}</p>}
            </div>
        </div>
    </div>
}